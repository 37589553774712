import { gql } from '@apollo/client';
import { SPOT_FULL, SPOT_LIVE_FRAGMENT, SPOT_MEMBERSHIP_HOST_FRAGMENT } from './spot-fragments';

export const TURN_OFF_SPOT_MUTATION = gql`
    mutation turnOffSpot($id: ID!, $turnBackOnAt: ISO8601Date, $turnOffReason: String) {
        turnOffSpot(id: $id, turnBackOnAt: $turnBackOnAt, turnOffReason: $turnOffReason) {
            id
            ...LiveFragment
        }
    }
    ${SPOT_LIVE_FRAGMENT}
`;

export const SPOT_UPDATE = gql`
    mutation updateSpot(
        $id: ID
        $size: Float
        $enclosureType: String
        $fenceHeight: String
        $fencingType: [String]
        $fencingDetail: String
        $dogsPresent: String
        $describeDogsPresent: String
        $peoplePresent: String
        $describePeoplePresent: String
        $domesticAnimalsPresent: String
        $describeDomesticAnimalsPresent: String
        $street: String
        $country: String
        $zip: String
        $state: String
        $city: String
        $latitude: Float
        $longitude: Float
        $instructions: String
        $manualPrice: Float
        $availabilityWindow: Int
        $minimumLength: Float
        $maximumLength: Int
        $autoPriceOn: Boolean
        $autoPriceMin: Float
        $autoPriceMax: Float
        $title: String
        $description: String
        $instantBook: Boolean
        $photos: [PhotoInputObject!]
        $accessPhotos: [PhotoInputObject!]
        $live: Boolean
        $isPrivate: Boolean
        $publish: Boolean
        $maximumDogsAllowed: Int
        $hostPresencePreference: String
        $reservationDelayTime: Int
        $breedSizeRestrictions: String
        $donateTo: String
        $donatePercent: String
        $tags: [String!]
        $privateEntry: String
        $describePrivateEntry: String
        $hazards: String
        $gaps: Boolean
        $rules: String
        $timezone: String
        $turnOffReason: String
        $turnBackOnAt: String
        $dogsAway: Boolean
        $peopleAway: Boolean
        $dogsAmenities: [String]
        $essentialAmenities: [String]
        $peopleAmenities: [String]
        $venueAmenities: [String]
        $publishType: Publish
        $memberPrice2Hour: Float
        $memberPrice4Hour: Float
        $memberPrice8Hour: Float
        $memberHostBenefits: String
        $maximumMembers: Int
        $offerMembership: Boolean
        $offerSniffpass: Boolean
        $activeMembership: Boolean
        $membershipAvailabilityWindow: Int
        $createdPlatform: CreatedPlatform
        $publishedPlatform: CreatedPlatform
        $confirmAvailability: Boolean
        $reservationReminders: Boolean
    ) {
        updateSpot(
            id: $id
            size: $size
            enclosureType: $enclosureType
            fenceHeight: $fenceHeight
            fencingType: $fencingType
            fencingDetail: $fencingDetail

            dogsPresent: $dogsPresent
            describeDogsPresent: $describeDogsPresent
            dogsAway: $dogsAway

            peoplePresent: $peoplePresent
            describePeoplePresent: $describePeoplePresent
            peopleAway: $peopleAway

            domesticAnimalsPresent: $domesticAnimalsPresent
            describeDomesticAnimalsPresent: $describeDomesticAnimalsPresent

            street: $street
            country: $country
            zip: $zip
            state: $state
            city: $city
            latitude: $latitude
            longitude: $longitude
            instructions: $instructions

            dogsAmenities: $dogsAmenities
            essentialAmenities: $essentialAmenities
            peopleAmenities: $peopleAmenities
            venueAmenities: $venueAmenities

            manualPrice: $manualPrice
            autoPriceOn: $autoPriceOn
            autoPriceMin: $autoPriceMin
            autoPriceMax: $autoPriceMax
            title: $title
            description: $description
            instantBook: $instantBook
            minimumLength: $minimumLength
            maximumLength: $maximumLength

            photos: $photos
            accessPhotos: $accessPhotos
            live: $live
            isPrivate: $isPrivate

            publish: $publish

            maximumDogsAllowed: $maximumDogsAllowed
            hostPresencePreference: $hostPresencePreference
            reservationDelayTime: $reservationDelayTime
            breedSizeRestrictions: $breedSizeRestrictions
            donateTo: $donateTo
            donatePercent: $donatePercent
            tags: $tags

            privateEntry: $privateEntry
            describePrivateEntry: $describePrivateEntry
            hazards: $hazards
            gaps: $gaps
            rules: $rules

            timezone: $timezone
            turnOffReason: $turnOffReason
            turnBackOnAt: $turnBackOnAt
            publishType: $publishType

            memberPrice2Hour: $memberPrice2Hour
            memberPrice4Hour: $memberPrice4Hour
            memberPrice8Hour: $memberPrice8Hour
            memberHostBenefits: $memberHostBenefits
            maximumMembers: $maximumMembers
            offerMembership: $offerMembership
            offerSniffpass: $offerSniffpass
            activeMembership: $activeMembership

            createdPlatform: $createdPlatform
            publishedPlatform: $publishedPlatform

            availabilityWindow: $availabilityWindow
            membershipAvailabilityWindow: $membershipAvailabilityWindow
            confirmAvailability: $confirmAvailability
            reservationReminders: $reservationReminders
        ) {
            spot {
                ...SpotFull
            }
            errors {
                message
                path
            }
        }
    }
    ${SPOT_FULL}
`;

export const M_ADD_SPOT_TO_FAVORITES = gql`
    mutation toggleFavorite($id: ID!) {
        addSpotToFavorites(id: $id) {
            id
        }
    }
`;

export const M_RM_SPOT_FROM_FAVORITES = gql`
    mutation toggleFavorite($id: ID!) {
        removeSpotFromFavorites(id: $id) {
            id
        }
    }
`;

export const SPOT_UPDATE_AMENITIES = gql`
    mutation updateSpotAmenities(
        $id: ID
        $dogsAmenities: [String]
        $essentialAmenities: [String]
        $peopleAmenities: [String]
        $venueAmenities: [String]
    ) {
        updateSpot(
            id: $id
            dogsAmenities: $dogsAmenities
            essentialAmenities: $essentialAmenities
            peopleAmenities: $peopleAmenities
            venueAmenities: $venueAmenities
        ) {
            spot {
                ...SpotFull
            }
            errors {
                message
                path
            }
        }
    }
    ${SPOT_FULL}
`;

export const SEND_PHOTO_LINK = gql`
    mutation sendPhotoLink($type: PhotoLinkTo!, $spotId: ID!, $phoneNumber: String) {
        sendPhotoLink(type: $type, spotId: $spotId, phoneNumber: $phoneNumber)
    }
`;

export const START_MEMBERSHIP_GUEST = gql`
    mutation startMembershipV2(
        $spotId: ID
        $hours: Int
        $dogs: Int
        $asHost: Boolean
        $pmId: String
        $createdFrom: CreatedFromMembership
        $sniffpass: Boolean
        $sniffpassType: MembershipSniffpass
        $period: SubscriptionPeriod
        $trial: Boolean
    ) {
        startMembershipV2(
            spotId: $spotId
            hours: $hours
            dogs: $dogs
            asHost: $asHost
            pmId: $pmId
            createdFrom: $createdFrom
            sniffpass: $sniffpass
            sniffpassType: $sniffpassType
            period: $period
            trial: $trial
        ) {
            id
            active
            price
        }
    }
`;

export const UPDATE_MEMBERSHIP_GUEST = gql`
    mutation updateMembership($id: ID!, $dogs: Int, $hours: Int, $active: Boolean, $sniffpassType: MembershipSniffpass) {
        updateMembership(id: $id, dogs: $dogs, hours: $hours, active: $active, sniffpassType: $sniffpassType) {
            id
        }
    }
`;

export const CANCEL_MEMBERSHIP_GUEST = gql`
    mutation cancelMembership($id: ID!, $reason: String!) {
        cancelMembership(id: $id, reason: $reason) {
            id
        }
    }
`;

export const SPOT_UPDATE_MEMBERSHIPS = gql`
    mutation updateSpotMemberships(
        $id: ID
        $maximumMembers: Int
        $memberHostBenefits: String
        $memberPrice2Hour: Float
        $memberPrice4Hour: Float
        $memberPrice8Hour: Float
        $memberReservationDelayTime: Int
        $activeMembership: Boolean
    ) {
        updateSpot(
            id: $id
            maximumMembers: $maximumMembers
            memberHostBenefits: $memberHostBenefits
            memberPrice2Hour: $memberPrice2Hour
            memberPrice4Hour: $memberPrice4Hour
            memberPrice8Hour: $memberPrice8Hour
            memberReservationDelayTime: $memberReservationDelayTime
            activeMembership: $activeMembership
        ) {
            spot {
                ...spotMembershipHostFragment
            }
            errors {
                message
                path
            }
        }
    }
    ${SPOT_MEMBERSHIP_HOST_FRAGMENT}
`;

export const CREATE_REPORT = gql`
    mutation createReport($id: ID!, $type: String!, $reason: String!) {
        createReport(id: $id, type: $type, reason: $reason) {
            id
        }
    }
`;

export const CREATE_CONTACT = gql`
    mutation createContact($spotId: ID!, $contacts: [String!]!) {
        createContact(spotId: $spotId, contacts: $contacts) {
            id
            contact
        }
    }
`;

export const DELETE_CONTACT = gql`
    mutation removeContact($ids: [ID!]!) {
        removeContact(ids: $ids) {
            id
        }
    }
`;
